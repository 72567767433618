import { isConnectedAccount } from 'owa-accounts-store';
import type { MailboxInfo } from 'owa-client-types';
import getMasterCategoryList from './getMasterCategoryList';
import type { CalendarEntry, LocalCacheForRemoteCalendarEntry } from 'owa-graph-schema';
import type CategoryType from 'owa-service/lib/contract/CategoryType';

export default function getMasterOrRemoteCategoryList(
    calendarEntry: CalendarEntry | undefined | null,
    mailboxInfo: MailboxInfo
) {
    let manageCategoriesDisabled = false;
    let createNewCategoryDisabled = false;
    let categoryList = getMasterCategoryList(mailboxInfo);

    if (mailboxInfo && isConnectedAccount(mailboxInfo.userIdentity)) {
        createNewCategoryDisabled = true;
    }

    const maybeCategoryList = (calendarEntry as LocalCacheForRemoteCalendarEntry)?.RemoteCategories
        ?.MasterList;

    if (maybeCategoryList as CategoryType[]) {
        // on remote calendars, we use the remote category list and don't allow managing categories
        manageCategoriesDisabled = true;
        createNewCategoryDisabled = true;
        categoryList = maybeCategoryList as CategoryType[];
    }

    if (mailboxInfo && isConnectedAccount(mailboxInfo.userIdentity)) {
        manageCategoriesDisabled = true;
    }

    return { manageCategoriesDisabled, createNewCategoryDisabled, categoryList };
}
