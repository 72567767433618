import type { MailboxInfo } from 'owa-client-types';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import { getAccountScopeUserSettings } from 'owa-session-store';

/**
 * Gets the MasterCategoryList for the given mailboxInfo.
 * @param mailboxInfo The mailbox to fetch the user configuration for
 */
export default function getMasterCategoryList(mailboxInfo: MailboxInfo): CategoryType[] {
    const userConfiguration = getAccountScopeUserSettings(mailboxInfo);
    const masterCategoryList = userConfiguration?.MasterCategoryList;
    return <CategoryType[]>masterCategoryList?.MasterList || [];
}
