import {
    type IColorCellProps,
    type IColorPickerGridCellStyleProps,
    type IColorPickerGridCellStyles,
} from '@fluentui/react/lib/SwatchColorPicker';
import { lightColorCodeValueMap } from '../store/helpers/getLightThemeColorCodeValueMap';
import { darkColorCodeValueMap } from '../store/helpers/getDarkThemeColorCodeValueMap';
import CategoryColor from '../store/schema/CategoryColor';
import { getIsDarkTheme } from 'owa-fabric-theme';
import React from 'react';

/**
 * Gets the new category picker cell
 * @returns the category picker cell new design
 */
export function renderCustomCategoryCell(cellProps?: IColorCellProps) {
    const colorId = cellProps?.id ?? CategoryColor.None.toString();
    const categoryColor = getIsDarkTheme()
        ? darkColorCodeValueMap[colorId]
        : lightColorCodeValueMap[colorId];
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <circle
                cx="16"
                cy="16"
                r="15"
                fill={categoryColor.primaryColor}
                stroke={categoryColor.secondaryColor}
                stroke-width="1"
            />
            <text
                x="16"
                y="17"
                text-anchor="middle"
                font-size="16"
                fill={categoryColor.textColor}
                stroke-width="0"
                dominant-baseline="middle"
            >
                A
            </text>
        </svg>
    );
}

/**
 * Gets the category picker cell custom styles for dialog box
 * @returns the new design of category picker cell styles for dialog box
 */
export function customColorGetColorGridCellStyles(
    props: IColorPickerGridCellStyleProps
): Partial<IColorPickerGridCellStyles> {
    const { theme, selected } = props;
    return {
        colorCell: [
            {
                height: 30,
                width: 30,
                selectors: {
                    '&:hover::before': {
                        boxShadow: 'none',
                    },
                    '&:focus': {
                        borderWidth: 0,
                    },
                    '&:hover': {
                        borderWidth: 2,
                        padding: 2,
                    },
                },
            },
            selected && {
                borderWidth: 2,
                padding: 2,
                borderColor: theme.palette.themeDarkAlt,
                border: '2px solid',
                selectors: {
                    '&:focus': {
                        borderWidth: 2,
                        padding: 2,
                        borderColor: theme.palette.themeDarkAlt,
                    },
                    '&:hover': {
                        borderWidth: 2,
                        padding: 2,
                        borderColor: theme.palette.themeDarkAlt,
                    },
                },
            },
        ],
    };
}

/**
 * Gets the category picker cell custom styles for context menu
 * @returns the new design of category picker cell styles for context menu
 */
export function contextMenuColorGridCellStyles(
    props: IColorPickerGridCellStyleProps
): Partial<IColorPickerGridCellStyles> {
    const { theme, selected } = props;
    return {
        colorCell: [
            {
                height: 20,
                width: 20,
                selectors: {
                    '&:hover::before': {
                        boxShadow: 'none',
                    },
                    '&:focus': {
                        borderWidth: 0,
                        padding: 0,
                    },
                    '&:hover': {
                        borderWidth: 2,
                        padding: 2,
                    },
                },
            },
            selected && {
                borderWidth: 2,
                padding: 2,
                borderColor: theme.palette.themeDarkAlt,
                border: '2px solid',
                selectors: {
                    '&:focus': {
                        borderWidth: 2,
                        padding: 2,
                        borderColor: theme.palette.themeDarkAlt,
                    },
                    '&:hover': {
                        borderWidth: 2,
                        padding: 2,
                        borderColor: theme.palette.themeDarkAlt,
                    },
                },
            },
        ],
    };
}
