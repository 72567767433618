import type CategoryType from 'owa-service/lib/contract/CategoryType';

const MAX_CATEGORIES_WHEN_NOT_ALL_SHOWN = 8;

export const getCategoriesToShow = (
    categoryList: CategoryType[],
    categoriesToCheck: string[],
    shouldShowClearCategories: boolean,
    isAllMode: boolean
): string[] => {
    const allCategories = categoryList.filter(category => category.Name != undefined);
    const doCategoriesHaveTimestamp = allCategories.every(
        (category: CategoryType) => category?.LastTimeUsed
    );
    let sortedCategories: CategoryType[];

    // Sort categories by alphabetical order in all mode
    if (isAllMode) {
        sortedCategories = allCategories.sort((a, b) => {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion.
             *	> Forbidden non-null assertion. */
            return a.Name!.localeCompare(b.Name!);
        });
    } else if (doCategoriesHaveTimestamp) {
        // Otherwise sort categories by most recently used
        sortedCategories = allCategories.sort((a, b) => {
            // If both categories have a LastUsedTime, compare them; otherwise sort the undefined one(s) to behind the defined one
            return b.LastTimeUsed && a.LastTimeUsed
                ? b.LastTimeUsed.localeCompare(a.LastTimeUsed)
                : !b.LastTimeUsed
                ? 1
                : -1;
        });
    } else {
        // If there is no timestamp, fallback to sorting by creation date
        sortedCategories = allCategories.reverse();
    }

    // Get the category names
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    let categoriesToShow = sortedCategories.map(category => category.Name!);

    if (!isAllMode) {
        // Put checked (applied) categories at the top of the list
        categoriesToCheck.forEach(category => {
            const index = categoriesToShow.indexOf(category);
            if (index != -1) {
                categoriesToShow.splice(index, 1);
            }
            categoriesToShow.unshift(category);
        });

        // Show 1 less category if displaying clear categories as an option
        const maxCategoriesToShow = shouldShowClearCategories
            ? MAX_CATEGORIES_WHEN_NOT_ALL_SHOWN - 1
            : MAX_CATEGORIES_WHEN_NOT_ALL_SHOWN;
        // Keep only the n most recently used categories
        categoriesToShow.splice(maxCategoriesToShow);

        // Sort remaining categories alphabetically, ignoring checked categories
        const sortedRemainingCategories = categoriesToShow
            .slice(categoriesToCheck.length)
            .sort((a, b) => {
                return a.localeCompare(b);
            });
        categoriesToShow = categoriesToShow
            .slice(0, categoriesToCheck.length)
            .concat(sortedRemainingCategories);
    }
    return categoriesToShow;
};
